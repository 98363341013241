@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.header-text {
  .anticon {
    vertical-align: 1px;
  }
}
.task-note-list {
  .collapse-expand-toggler {
    color: $quest-navy !important;
    padding: 4px 0;
    .anticon {
      vertical-align: 2px;
    }
  }
  .workflow-notes-table {
    margin-top: 1em;

    .date,
    .user {
      vertical-align: top;
    }

    .action-btn {
      .anticon {
        color: #c4c4c4;
        vertical-align: 3px;
      }
      .edit-button {
        margin-left: 5px;
      }
    }
  }
}
