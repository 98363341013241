@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";
@import "../../../theme/components/extended-antd/assets/scss/variables";

.security-wrapper {
  border: 1px solid $quest-dark-grey;
  padding: 1em;
  color: $quest-navy;

  .header {
    font-weight: bold;
    color: $quest-navy;
    margin-bottom: 2em;
  }

  .section-header {
    font-weight: bold;
    color: $quest-navy;
    margin-bottom: 1em;
  }

  .ant-typography {
    color: $quest-navy;
  }

  .supplier-row {
    margin-bottom: 2em;
  }

  .info {
    margin-bottom: 1em;
  }

  .agreed-value {
    .ant-input-affix-wrapper {
      width: calc(100% - 50px);
    }
    .anticon {
      vertical-align: 2px;
    }
  }

  .market-value-lvr {
    padding-top: 5px;
  }
}
