@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";
@import "../../../common/assets/scss/button";

.approval-conditions-wrapper {
  margin-bottom: 1em;

  .approval-conditions {
    .ant-checkbox-wrapper:hover .ant-checkbox::after {
      visibility: hidden;

      .ant-checkbox-checked {
        &:hover::after {
          visibility: hidden !important;
        }
      }
    }
  }
}
