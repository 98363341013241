@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.supporting-documents {
  margin: 1rem 0 1rem;

  .documents-tabs {
    .ant-tabs-tab {
      color: $quest-navy !important;
      font-weight: bold !important;

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: $quest-navy !important;
        }
      }
    }

    .ant-tabs-ink-bar {
      background-color: $quest-green !important;
    }
  }
  .list-pagination {
    text-align: right;
    margin-top: 1rem;

    .anticon {
      vertical-align: 1px;
    }
  }
  .quest-uploader.hidden {
    display: none;
  }
}
