@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.funder-section {
  margin-bottom: 2rem;
  .form-row-item {
    padding: 0 0 0.5rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;

    > * {
      width: 200px;
    }
    .invalid {
      border: 1px solid $quest-red;
    }
  }
  .submit-info {
    margin-top: 0.5rem;
  }
}
