@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.aba-details {
  display: flex;
  gap: 2rem;

  .panel {
    .heading {
      margin-bottom: 1rem;
      font-weight: 800;
      font-size: 16px;
      color: $quest-navy;
    }
  }
  .left-panel {
    max-width: 400px;
  }
  .right-panel {
    flex-grow: 1;
    max-width: 700px;
  }
  .summary {
    border: 1px solid #f0f0f0;
    padding: 1rem;
    color: inherit;

    td {
      vertical-align: top;
      padding: 0.25rem;
    }
    button {
      color: $quest-navy;
    }
  }
  .aba-trx {
    margin-bottom: 1rem;

    th {
      font-weight: 600;
      color: $quest-navy;
    }
    td {
      vertical-align: top;
      color: $quest-navy;
    }
  }
  .act-details {
    .others {
      color: #c4c4c4;
      display: flex;
      gap: 0.5rem;
    }
  }
}
