@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.credit-ref {
  .list {
    margin: 1rem 0;
    padding-left: 1rem;
    color: $quest-navy;

    li {
      margin-top: 0.25rem;
    }
    strong {
      text-transform: uppercase;
    }
    .action-btn {
      color: #cecece;
      vertical-align: 1px;
      margin-left: 0.5rem;

      &.edit {
        margin-left: 1rem;
      }
    }
  }
  .ant-popover-inner-content p {
    margin: 0;
  }
  .form {
    .ant-input,
    .ant-picker,
    .ant-input-number-affix-wrapper {
      width: 100%;
    }
    .invalid {
      border-color: $quest-red;
    }
    .validation {
      color: $quest-red;
      font-size: 12px;
    }
    .field-label {
      color: $quest-navy;
    }
    .submit {
      margin: 1.5rem auto 0;
      min-width: 150px;
      display: block;
    }
  }
}
