@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.emailer {
  margin-top: 1rem;

  .ant-popover-title {
    color: $quest-grey;
  }
  .template-option {
    cursor: not-allowed;
    opacity: 0.8;

    &.enabled {
      cursor: pointer;
      opacity: 1;

      &:hover {
        font-weight: bold;
      }
    }
  }
  .approved {
    .template-option.approved {
      display: block;
    }
  }
}
