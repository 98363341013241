@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.qf-outcome {
  min-width: 200px;

  .qf-workflow-outcome {
    .ant-radio-button-wrapper {
      min-width: 60px;
      text-align: center;

      &:not(:first-child)::before {
        background-color: unset !important;
      }
    }

    .outcome-YES {
      color: $quest-green;
      background-color: #fff;
      border-color: $quest-green;
      margin-right: 5px;

      &.ant-radio-button-wrapper-checked {
        background-color: $quest-green;
        color: #fff;
        border-color: $quest-green;
      }
    }
    .outcome-NO {
      color: $quest-red;
      background-color: #fff;
      border-color: $quest-red !important;
      border: 1px solid;
      margin-right: 5px;

      &.ant-radio-button-wrapper-checked {
        background-color: $quest-red;
        color: #fff;
        border-color: $quest-red;
        &:not(.ant-radio-button-wrapper-disabled)::before {
          background-color: $quest-red;
        }
      }
    }
    .outcome-ELSE {
      color: #fadb14;
      background-color: #fff;
      border: 1px solid;
      border-color: #fadb14 !important;

      &.ant-radio-button-wrapper-checked {
        background-color: #fadb14;
        color: #fff;
        border-color: #fadb14;
        &:not(.ant-radio-button-wrapper-disabled)::before {
          background-color: #fadb14;
        }
      }
    }
  }
}
