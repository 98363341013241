@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.payment-aba-process {
  .process-section {
    min-height: 200px;
  }
  .aba-file-upload {
    margin-bottom: 2rem;

    button {
      color: $quest-navy;
    }
  }
  .process-section {
    .upload-btn {
      margin-bottom: 1rem;

      .anticon {
        vertical-align: 1px;
      }
    }
  }
}
