@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.move-application {
  background: #fff;
  padding: 1rem;

  .panel-cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 900px;

    .panel {
      width: 45%;
      padding: 1rem 0;

      .header {
        font-weight: bold;
        margin-bottom: 0.5rem;
        color: $quest-navy;
      }

      &.new-owner {
        color: $quest-navy;
        background: #f0f2f5;
        padding: 1rem;
        width: 50%;
      }
    }
  }
  .error-msg {
    max-width: 900px;
    margin-bottom: 1rem;

    .status {
      text-transform: uppercase;
    }
  }
  .source-account .label {
    color: $quest-grey;
  }
  input[readonly] {
    cursor: not-allowed;

    &:hover,
    &:focus {
      border-color: #d9d9d9;
      box-shadow: none;
    }
  }
  .ant-select {
    width: 100%;
  }
  .loan-num {
    color: $quest-navy;
  }
  .btn-cont {
    text-align: right;
    margin-top: 1rem;

    button {
      margin-left: 0.5rem;
    }
  }
}
