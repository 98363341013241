@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.notes-list {
  color: $quest-navy;

  h4 {
    color: $quest-navy;
    margin-bottom: 1.5rem;

    .anticon {
      color: $quest-grey;
      vertical-align: 3px;
      margin-left: 0.5rem;
    }
  }
  td {
    vertical-align: top;
  }
  .date {
    width: 90px;
  }
  .section {
    min-width: 200px !important;
  }
  .user {
    max-width: 200px;
  }
  .action {
    width: 70px;

    .anticon {
      color: $quest-grey;
      vertical-align: 3px;
      margin-left: 0.5rem;
    }
  }
  .ant-pagination {
    text-align: right;
    margin: 1rem 0 0;

    button .anticon {
      vertical-align: 1px;
    }
  }
}
