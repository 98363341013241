@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.aba-history {
  thead {
    th {
      color: $quest-navy;
      font-weight: bold;
    }
  }
  tbody {
    color: $quest-navy;

    .credit {
      color: #eb5757;
    }
  }
  .download-col {
    position: relative;

    .download-icon {
      font-size: 20px;
    }
    .error {
      position: absolute;
      top: 0px;
      right: -2px;

      * {
        color: $quest-red-error;
      }
    }
  }
}
