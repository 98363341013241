@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";
@import "../../../../theme/components/extended-antd/assets/scss/fontfaces";
@import "../../../../theme/components/extended-antd/assets/scss/variables";
@import "../../../assets/scss/variables";

.workflow-task-details {
  overflow-y: auto;
  overflow-x: none;
  max-height: 970px;
  .ant-collapse-item {
    margin-bottom: 1em;
  }
  .workflow-task-collapse-section {
    .ant-collapse-header {
      height: 50px;
      background-color: #e5e5e5;
      h4 {
        margin-bottom: 0;
      }
    }
    .ant-collapse-content-box {
      padding-top: 1em !important;
    }
  }
  .task-header {
    h4 {
      margin-bottom: 0;
    }
    padding: 12px 14px;
    margin-bottom: 0.5em;
    &.pass {
      background-color: rgba($state-pass, 0.3);
    }
    &.hold {
      background-color: rgba($state-hold, 0.3);
    }
    &.refer {
      background-color: rgba($state-refer, 0.3);
    }
    &.fail {
      background-color: rgba($state-fail, 0.3);
    }
  }
  .task-update-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;

    .state-btn-content {
      .ant-dropdown-menu-title-content {
        button {
          width: 100%;
        }
      }

      [class*="-invert"] {
        font-weight: bold;
      }

      .state-btn {
        border-bottom-width: 2px;
        border-top-width: 2px;
        border-left-width: 2px;
        border-right-width: 2px;
      }
    }
  }

  .workflow-document-list {
    margin-bottom: 1em;
    tbody {
      .btn-download {
        border: none;
        color: $quest-navy;
        span {
          text-decoration: underline !important;
        }
      }
    }
  }
  .workflow-task-notes {
    textarea {
      min-height: 140px;
      max-height: 300px;
    }
  }
}
