@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.workflow-notes {
  background: #fff;

  td {
    vertical-align: top;
  }
  .date {
    width: 90px;
  }
  td.date {
    color: $quest-navy;
  }
  .group,
  .task {
    max-width: 100px;
  }
  .name {
    white-space: nowrap;
  }
  .toggle-all-notes {
    margin-bottom: 1rem;
  }
}
