@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.approval-email {
  .expiry {
    width: 100%;
  }

  .send-btn {
    margin-top: 2rem;
    margin-left: auto;
    display: block;
    min-width: 100px;
  }

  ul {
    padding-left: 20px;
    margin-bottom: 0;
  }

  .label,
  .value {
    color: $quest-navy;
  }

  .label {
    font-weight: bold;
  }

  .rerun-credit-file-warning,
  span.expired {
    color: #f35737;
  }
}
