@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.approval-expiry {
  color: $quest-navy;
  // padding: 0.5rem 0;

  .label {
    font-weight: bold;
    color: #3dc13c;
  }
  &.expired {
    .label {
      color: $quest-red;
    }
  }
}
