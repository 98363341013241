.introducer-notes {
  .note {
    border: 2px solid #ededed;
    padding: 0.5rem 1rem;
    white-space: pre-wrap;
    min-height: 100px;
    max-height: 300px;
    overflow: auto;
  }
}
