.qf-collapse {
  .ant-collapse-arrow {
    color: #fff;
    background: #1a0352;
    padding: 0.25rem;
  }
  .ant-collapse-content-box {
    padding: 16px 16px 20px !important;
  }
}
