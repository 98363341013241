@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.audit-log-list {
  .category-select {
    .ant-select-clear {
      opacity: 1 !important;
    }
  }
  .section-header-1 {
    margin-bottom: 1rem;
  }
  .expand-button {
    color: $quest-navy !important;
    padding: 5px 0;
    margin-bottom: 1em;

    .anticon {
      vertical-align: 2px;
    }
  }
  table {
    .changes-header {
      margin-bottom: 1em;
    }
    .audit-changes {
      .audit-changes-content {
        overflow-x: auto;
        .value {
          font-weight: 600;
        }
      }

      &.expandable {
        .audit-changes-content {
          &.clamped {
            overflow-y: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
        }
      }

      .button-container {
        display: flex;
        justify-content: center;
      }
    }

    td {
      vertical-align: top;
    }
  }
}
