@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";
@import "../../../../../theme/components/extended-antd/assets/scss/variables";

@mixin application-data-panel {
  border: 1px solid $quest-dark-grey;
  padding: 1rem;

  .header {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    min-height: 50px;

    .label {
      color: $quest-navy;
      font-weight: bold;
      font-size: 16px;
      margin: 0;
    }
    a {
      margin-left: 0.5rem;

      .anticon {
        vertical-align: 2px;
      }
    }
  }
  .collapsible {
    .ant-collapse-item:not(:last-child) {
      margin-bottom: 1rem;
    }
    .ant-collapse-header {
      background-color: #edeced;
      color: $quest-navy;
      font-weight: bold;
      font-size: 16px;
    }
    .ant-collapse-content-box {
      padding: 1rem 0;
    }
    .panel {
      .section-header {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 0.5rem;
      }
      .info {
        margin-bottom: 1rem;

        .label {
          font-weight: bold;
        }
        .value {
          color: $quest-navy;
          font-size: 14px;
        }
      }
    }
  }
}

@mixin credit-score {
  .anticon {
    vertical-align: 2px;
  }
  .confirm-message {
    text-align: center;
  }
}

.applicant-guarantors {
  .box {
    border: 2px solid #ededed;
    padding: 1rem;
  }
}
