@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.notes-section {
  margin-top: 1rem;

  .heading {
    margin-bottom: 1rem;
    color: $quest-navy;
    font-weight: bold;
    font-size: 16px;

    .anticon {
      vertical-align: 1px;
      color: $quest-navy;
    }
  }
}
