@import "../../pages/loan/admin/applicant-guarantors/ApplicantGuarantors.scss";
@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";
@import "../../../theme/components/extended-antd/assets/scss/variables";

.guarantors-wrapper {
  @include application-data-panel;

  .credit-buttons-header {
    margin-left: auto;
    .equifax {
      margin-right: 1em;
    }
    .illion {
      margin-right: 1em;
    }
  }

  .credit-buttons {
    .illion {
      margin-right: 1em;
    }
  }
  .ant-divider {
    margin-bottom: 0px;

    &.sum {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      &.total {
        margin-top: 1em;
      }
    }
  }
  .assets-liabilities {
    > table {
      width: 100%;
      table-layout: fixed;
      > thead > tr > th {
        color: #c4c4c4;
      }

      .value {
        text-align: right;
      }

      td {
        color: $quest-navy;
      }
    }
  }
  .net-assets {
    td {
      color: $quest-navy;
    }
  }

  .ant-divider {
    border-top: 1px solid $quest-dark-grey;
  }

  @media (max-width: 1440px) {
    .header {
      flex-direction: column;

      .header-title {
        width: 100%;
        label {
          text-align: left;
        }

        margin-bottom: 1rem;
      }
    }
  }

  @media (max-width: 1200px) {
    .header {
      .credit-buttons-header {
        button {
          margin-bottom: 1rem;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .header {
      .header-title {
        width: 100%;
        label {
          text-align: left;
        }

        .credit-buttons-header {
          button {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .header {
      flex-direction: row;
      .header-title {
        width: auto;
        label {
          text-align: left;
        }

        .credit-buttons-header {
          button {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      .header-title {
        width: 100%;
        label {
          text-align: left;
        }
        label {
          text-align: left;
        }
      }
    }
  }
}
