@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";
@import "../../../theme/components/extended-antd/assets/scss/variables";

.quote-expand {
  .ant-collapse-header {
    h3 {
      color: $quest-navy;
    }
  }
}

.quote-wrapper {
  border: 1px solid $quest-dark-grey;
  padding: 1em;
  color: $quest-navy;

  .header {
    font-weight: bold;
    color: $quest-navy;
    margin-bottom: 2em;
  }

  .financials-divider {
    margin-top: 0.4em;
    margin-bottom: 0.4em;
  }

  .section-header {
    font-weight: bold;
    color: $quest-navy;
    margin-bottom: 1em;
  }

  .ant-typography {
    color: $quest-navy;
  }

  .numbers-table {
    width: 100%;
    table-layout: fixed;
    td.value {
      text-align: right;
    }
  }

  .info {
    margin-bottom: 1rem;
  }
}
