.assignees {
  display: flex;
  align-items: flex-end;

  .ant-form-item {
    margin-bottom: 0;
  }
  .field {
    flex-grow: 1;
    margin-right: 1rem;
  }
  .anticon {
    vertical-align: 1px;
  }
}
