.assessment-status {
  display: flex;
  align-items: flex-end;

  .ant-form-item {
    margin-bottom: 0;
  }
  .field {
    flex-grow: 1;
    margin-right: 1rem;
    .reason {
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .anticon {
    vertical-align: 1px;
  }

  .status-reason {
    white-space: pre-line;
  }

  .setllement-date {
    white-space: pre-line;
  }
}
