@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";
@import "../../../theme/components/extended-antd/assets/scss/fontfaces";

.status-reason-modal {
  font-family: "SF Pro Text";
  .ant-modal-close {
    top: -60px;
    right: -13px;
    span {
      svg {
        fill: $quest-navy;
        width: 31.5px;
        height: 33.13px;
      }
    }
  }
  .ant-modal-header {
    border-top: 10px solid $quest-navy;
  }
  .status-save-btn {
    margin: 1.5rem auto 0;
    min-width: 150px;
    display: block;
  }
}
