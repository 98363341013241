@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";
@import "../../../theme/components/extended-antd/assets/scss/variables";

.workflow-documents {
  min-height: 40px;

  table {
    width: 100%;
    border: 1px solid $quest-dark-grey;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }
  td {
    border: 1px solid $quest-dark-grey;
    padding: 0.5em;
    color: $quest-navy;
    vertical-align: top;

    a {
      text-decoration: underline;
      color: $quest-navy;
    }
    &.action {
      width: 30px;
      text-align: center;
    }
    .delete-button {
      cursor: pointer;
      color: #cecece;
    }
  }
}
