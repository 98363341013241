@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.qf-btn-navy {
  color: #fff;
  background-color: $quest-navy;
  border-color: transparent;

  &:not(&:disabled) {
    &:hover {
      opacity: 0.6;
      color: #fff;
      background-color: $quest-navy;
      border-color: transparent;
    }
    &:focus,
    &:active {
      color: #fff;
      background-color: $quest-navy;
      border-color: transparent;
    }
  }
}

.qf-btn-navy-invert {
  color: $quest-navy;
  background-color: #fff;
  border-color: $quest-navy;

  &:not(&:disabled) {
    &:hover {
      color: $quest-navy;
      background-color: darken($color: #fff, $amount: 1);
      border-color: $quest-navy;

      &.hover-fill {
        background-color: $quest-navy;
        color: #fff;
      }
    }
    &:focus,
    :active {
      color: $quest-navy;
      background-color: #fff;
      border-color: $quest-navy;
    }
  }
}

.qf-btn-green {
  color: $quest-navy;
  background-color: $quest-green;
  border-color: transparent;

  &:not(&:disabled) {
    &:hover {
      opacity: 0.6;
      color: $quest-navy;
      background-color: $quest-green;
      border-color: transparent;
    }
    &:focus,
    &:active {
      color: $quest-navy;
      background-color: $quest-green;
      border-color: transparent;
    }
  }
}

.qf-btn-green-invert {
  color: $quest-green;
  background-color: #fff;
  border-color: $quest-green;

  &:not(&:disabled) {
    &:hover {
      color: $quest-green;
      background-color: darken($color: #fff, $amount: 1);
      border-color: $quest-green;

      &.hover-fill {
        background-color: $quest-green;
        color: #fff;
      }
    }
    &:focus,
    :active {
      color: $quest-green;
      background-color: #fff;
      border-color: $quest-green;
    }
  }
}

.qf-btn-red {
  color: #fff;
  background-color: #eb3359;
  border-color: transparent;

  &:not(&:disabled) {
    &:hover {
      opacity: 0.6;
      color: #fff;
      background-color: #eb3359;
      border-color: transparent;
    }
    &:focus,
    &:active {
      color: #fff;
      background-color: #eb3359;
      border-color: transparent;
    }
  }
}

.qf-btn-red-invert {
  color: #eb3359;
  background-color: #fff;
  border-color: #eb3359;

  &:not(&:disabled) {
    &:hover {
      color: #eb3359;
      background-color: darken($color: #fff, $amount: 7.5);
      border-color: #eb3359;

      &.hover-fill {
        background-color: #eb3359;
        color: #fff;
      }
    }
    &:active,
    &:focus {
      color: #eb3359;
      background-color: #fff;
      border-color: #eb3359;
    }
  }
}

.qf-btn-orange {
  color: #fff;
  background-color: #fa8c16;
  border-color: transparent;

  &:not(&:disabled) {
    &:hover {
      opacity: 0.6;
      color: #fff;
      background-color: #fa8c16;
      border-color: transparent;
    }
    &:focus,
    &:active {
      color: #fff;
      background-color: #fa8c16;
      border-color: transparent;
    }
  }
}

.qf-btn-orange-invert {
  color: #fa8c16;
  background-color: #fff;
  border-color: #fa8c16;

  &:not(&:disabled) {
    &:hover {
      color: #fa8c16;
      background-color: darken($color: #fff, $amount: 7.5);
      border-color: #fa8c16;

      &.hover-fill {
        background-color: #fa8c16;
        color: #fff;
      }
    }
    &:active,
    &:focus {
      color: #fa8c16;
      background-color: #fff;
      border-color: #fa8c16;
    }
  }
}

.qf-btn-yellow {
  color: $quest-navy;
  background-color: #fadb14;
  border-color: transparent;

  &:not(&:disabled) {
    &:hover {
      opacity: 0.6;
      color: $quest-navy;
      background-color: #fadb14;
      border-color: transparent;
    }
    &:focus,
    &:active {
      color: $quest-navy;
      background-color: #fadb14;
      border-color: transparent;
    }
  }
}

.qf-btn-yellow-invert {
  color: #fadb14;
  background-color: #fff;
  border-color: #fadb14;

  &:not(&:disabled) {
    &:hover {
      color: #fadb14;
      background-color: darken($color: #fff, $amount: 7.5);
      border-color: #fadb14;

      &.hover-fill {
        background-color: #fadb14;
        color: #fff;
      }
    }
    &:active,
    &:focus {
      color: #fadb14;
      background-color: #fff;
      border-color: #fadb14;
    }
  }
}

.qf-btn-gray {
  color: #fff;
  border-color: transparent;
  background-color: #c4c4c4;

  &:not(:disabled) {
    &:hover {
      opacity: 0.6;
      color: #fff;
      border-color: transparent;
      background-color: #c4c4c4;
    }
    &:focus,
    &:active {
      color: #fff;
      border-color: transparent;
      background-color: #c4c4c4;
    }
  }
}

.qf-btn-gray-invert {
  color: #c4c4c4;
  border-color: #c4c4c4;
  background-color: #fff;

  &:not(:disabled) {
    &:hover {
      opacity: 0.6;
      color: #c4c4c4;
      border-color: #c4c4c4;
      background-color: #fff;

      &.hover-fill {
        background-color: #c4c4c4;
        color: #fff;
      }
    }
    &:focus,
    &:active {
      color: #c4c4c4;
      border-color: #c4c4c4;
      background-color: #fff;
    }
  }
}
