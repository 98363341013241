@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";
@import "../../../common/assets/scss/button";

.settlement-approval {
  margin-top: 1em;
  margin-bottom: 1em;
  .section-header-1 {
    display: flex;
    width: 100%;

    h3 {
      font-weight: bold;
      margin-bottom: 0;
      margin-right: 1em;
    }
  }
  .filters {
    display: flex;
    margin-bottom: 1rem;

    .state-btn {
      font-size: 12px;
      height: 32px;
      width: 32px;
      line-height: 32px;
      text-align: center;
      display: inline-block;
      border-radius: 50%;
      color: #fff;
      margin: 0 0.5rem;
      cursor: pointer;

      &.decline {
        background-color: $quest-red;
      }
      &.hold {
        background-color: #fa8c16;
      }
      &.refer {
        background-color: #fadb14;
      }
      &.all {
        color: #c4c4c4;
        border: 1px solid #c4c4c4;
      }
    }
  }
  .conditions-list-table {
    width: 100%;
    border-collapse: collapse;
    border-left: none;

    th,
    td {
      padding: 0.5em;
      color: $quest-navy;
      border: 1px solid #ececec;
      border-left: none;
      font-size: 12px;
    }

    > thead > tr {
      :first-child {
        background-color: #fff;
        border-top: none;
        text-align: center;
      }
      th {
        background-color: #fafafa;
      }
    }

    .action-buttons {
      .ant-spin-container {
        display: flex;
        justify-content: space-between;
      }

      button {
        font-size: 12px;
        min-width: 50px;
        height: 32px !important;
        border-color: #c4c4c4;
        background-color: #fff;
        color: #c4c4c4;
        min-width: 50px;
        font-size: 12px;

        &:not(:disabled) {
          &:hover {
            border-color: $quest-navy;
            background-color: $quest-navy;
            color: #fff;
          }
        }
        &.selected {
          border-color: $quest-green;
          background-color: $quest-green;
          color: $quest-navy;

          &:not(:disabled) {
            cursor: default;
          }
        }
      }
    }
  }
}
