@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";
@import "../../../theme/components/extended-antd/assets/scss/variables";

.contracts-list {
  .gen-contract-link {
    margin-bottom: 1rem;
    color: $quest-navy;
    font-weight: bold;
    font-size: 16px;

    .anticon {
      vertical-align: 1px;
      color: $quest-navy;
    }
  }
  .contracts-table {
    width: 100%;
    border: 1px solid $quest-dark-grey;
    border-collapse: collapse;
    table-layout: fixed;

    td {
      border: 1px solid $quest-dark-grey;
      padding: 0.5em;
      color: $quest-navy;

      a {
        text-decoration: underline;
        color: $quest-navy;
      }
    }
  }
}
