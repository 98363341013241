@import "../assets/scss/index";

.default-layout {
  .layout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $quest-navy;
    height: $header-height * 1px;
    width: 100%;
    max-width: $max-width;
    position: fixed;
    top: 0;
    padding: 0 1.5rem;
    z-index: 100;

    .logo {
      width: auto;
      height: 60%;

      img {
        height: 100%;
      }
    }
    .avatar {
      background: #05f58f;
      cursor: pointer;

      > span {
        vertical-align: 2px;
      }
    }
    .user-menu {
      min-width: 130px !important;
      top: 60px !important;

      a {
        text-decoration: none;
      }
    }
  }
}
