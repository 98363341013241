@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.word-limitter {
  .text {
    white-space: pre-line;
  }
  &:not(.expandable) {
    display: block;
    a {
      display: none;
    }
  }

  &.expandable {
    .text {
      &.clamped {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
    }
  }

  .toggle-btn {
    color: $quest-navy;
    font-weight: bold;
    font-style: italic;
  }
}
