@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";
@import "../assets/scss/index";

@mixin banner-adjustments($banner-count) {
  $adjustment: $banner-height * $banner-count;
  padding-top: ($header-height + $bread-crumb-height + $adjustment) * 1px;

  .layout-header {
    top: $adjustment * 1px;
  }
  .layout-crumbs {
    top: ($header-height + $adjustment) * 1px;
  }
  .layout-sider {
    top: ($header-height + $bread-crumb-height + $adjustment) * 1px;
  }
}
.default-layout {
  font-family: "SF Pro Text";
  min-height: 100vh;
  background: #fff;
  padding-top: ($header-height + $bread-crumb-height) * 1px;
  background: $quest-navy;
  margin: 0 auto;

  & > .inner {
    min-height: 100vh;
    max-width: $max-width;
    width: 100%;
    background: #46228d;
    margin: 0 auto;
  }
  .layout-crumbs {
    padding: 0.5rem 1.5rem;
    background: #f0f2f5;
    position: fixed;
    top: $header-height * 1px;
    min-height: 40px;
    width: $max-width;
    z-index: 2;
    font-size: 18px;
    line-height: 20px;

    a {
      color: #8c8c8c;

      &:hover {
        color: $quest-navy;
      }
    }
    > span:last-child {
      color: $quest-navy;
    }
  }
  .layout-body {
    margin: 0 1rem;
  }
  .layout-content {
    width: calc(100% - #{$sider-width});
    float: right;

    .header-1 {
      color: $quest-navy;
      font-weight: bold;
      font-size: 20px;
    }
  }
  .banner-container {
    position: fixed;
    top: 0;
    max-width: $max-width;
  }
  &.one-banner {
    @include banner-adjustments(1);
  }
  &.two-banner {
    @include banner-adjustments(2);
  }
  .content-section {
    padding: 1rem;
    margin-bottom: 1rem;
    background: #fff;
  }
  .qf-divider {
    border-top-color: $quest-dark-grey;
  }
}
