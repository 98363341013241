@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";
@import "../../../theme/components/extended-antd/assets/scss/variables";

.document-list {
  min-height: 30px;

  .document-table {
    width: 100%;
    border: 1px solid $quest-dark-grey;
    border-collapse: collapse;
    table-layout: fixed;

    td {
      border: 1px solid $quest-dark-grey;
      padding: 0.5em;
      color: $quest-navy;

      &.action {
        width: 30px;
        text-align: center;
      }
      .delete-button {
        cursor: pointer;
        color: #cecece;
      }
      a {
        text-decoration: underline;
        color: $quest-navy;
      }
    }
  }
}
