@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.ce-navigation {
  background: #fff;
  width: 100%;
  overflow: auto;
  padding: 1rem;
  position: sticky;
  top: 100px;
  z-index: 2;
  background: #fff;
  border-bottom: 2px solid #f0f2f5;

  .inner {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    position: relative;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      z-index: 0;
      border-top: 1px solid $quest-navy;
      top: 50%;
      width: 100%;
    }
    a {
      border: 1px solid $quest-navy;
      color: $quest-navy;
      height: 100%;
      padding: 0.25rem 1.5rem;
      text-decoration: none;
      position: relative;
      z-index: 1;
      background: #fff;
      white-space: nowrap;
      margin: 0 0.5rem;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      &.complete {
        background: $quest-green;
      }
      &.active {
        color: #fff;
        background: $quest-navy;

        .anticon {
          color: $quest-green;
        }
      }
    }
    .anticon {
      margin-right: 7px;
      vertical-align: 1px;
    }
  }
}
