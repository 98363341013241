@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.quest-uploader {
  .ant-upload.ant-upload-drag:hover {
    border-color: $quest-navy !important;
  }
  .ant-upload-drag-icon {
    margin-bottom: 10px !important;

    .anticon {
      color: $quest-navy !important;
    }
  }
  .ant-upload-text {
    color: $quest-navy !important;

    &.upload-info {
      font-size: 12px !important;
      font-weight: normal;
    }
  }
  .ant-upload-progress {
    width: 300px;
    max-width: 70%;
    margin: 0 auto;
  }
}

.invalid-files {
  width: 100%;
  margin: 0.5rem 0 1rem;

  tbody {
    tr {
      td {
        background-color: #fff;
        padding: 0.5em;
        color: $quest-red;
        vertical-align: top;
        border-bottom: 1px solid #ececec;

        &.validation {
          width: 160px;
        }
      }
    }
  }
}
