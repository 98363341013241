@import "../../../common/assets/scss/form";
@import "../../../theme/components/extended-antd/assets/scss/variables";

.application-overview {
  position: relative;
  padding: 1rem;
  background: #fff;
  margin-bottom: 1rem;

  a {
    color: $quest-navy;
    text-decoration: underline;
  }

  .introducer {
    color: $quest-navy;
    border: 1px solid $quest-dark-grey;
    padding: 0.5rem;
    height: 100%;
  }
}
