@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";
@import "../../../theme/components/extended-antd/assets/scss/fontfaces";

.confirm-lms-modal {
  font-family: "SF Pro Text";
  .confirmation-text {
    text-align: center;
    margin-bottom: 1rem;
    color: $quest-navy;
  }

  .confirmation-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      &:first-child {
        margin-right: 1rem;
      }
    }
  }

  .ant-modal-close {
    top: -60px;
    right: -13px;
    span {
      svg {
        fill: $quest-navy;
        width: 31.5px;
        height: 33.13px;
      }
    }
  }
  .ant-modal-header {
    border-top: 10px solid $quest-navy;
  }
}
