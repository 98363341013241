@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.loan-admin {
  .application-data {
    .label,
    label {
      color: #c4c4c4 !important;
    }
  }
  .section-header-1 {
    color: $quest-navy;
    font-weight: bold;
    font-size: 20px;
  }
  .section-header-3 {
    color: $quest-navy;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0.5rem;
  }
  .edit-link {
    color: $quest-navy;
    vertical-align: 3px;
  }
}
