@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";
@import "../assets/scss/index";

.default-layout {
  .layout-sider {
    list-style: none;
    margin: 0;
    padding: 0;

    a,
    .menu-item,
    .toggle-btn {
      display: flex;
      align-items: center;
      padding: 11px 20px;
      border-bottom: 1px solid #f0f2f5;
      border-right: 1px solid #f0f2f5;
      color: $quest-navy;
      text-decoration: none;
      cursor: pointer;
      background: #fff;

      &:hover {
        background: #97fbd0;
        transition: background 0.5s;
      }
    }
    .toggle-btn {
      color: #05f58f;
      padding: 22px 20px;
      transition: 0.3s;

      &:hover {
        color: $quest-navy;
      }
    }
    .anticon {
      vertical-align: 1px;
      font-size: 20px;
    }
    .title {
      margin-left: 0.75rem;
    }
  }

  // @media (min-width: 576px) {
  .layout-sider {
    position: fixed;
    width: $sider-width;
    top: ($header-height + $bread-crumb-height) * 1px;
    bottom: 0px;
    z-index: 1;
  }

  &.sider-collapsed {
    .layout-sider {
      width: $sider-width-collapsed;

      .title {
        display: none;
      }
    }
    .layout-content {
      width: calc(100% - #{$sider-width-collapsed});
    }
  }
  // }
}
