@import "../../assets/scss/variables";

.workflow-item {
  cursor: pointer;

  &.active {
    &.pass td {
      background: rgba($state-pass, 0.3);
    }
    &.hold td {
      background: rgba($state-hold, 0.3);
    }
    &.refer td {
      background: rgba($state-refer, 0.3);
    }
    &.failed td {
      background: rgba($state-fail, 0.3);
    }
    &.pending td {
      background-color: rgba($state-pending, 0.2);
    }
  }
  &:not(.active):hover {
    background: #fafafa;
  }
  .task-state {
    border-color: transparent;
    width: 66px;
    text-align: center;
    font-size: 14px;

    &.failed {
      background-color: $state-fail;
    }
    &.hold {
      background-color: $state-hold;
    }
    &.refer {
      background-color: $state-refer;
    }
    &.pending {
      border-color: $state-pending;
      background-color: #fff;
    }
    &.pass {
      background-color: $state-pass;
    }
  }
  .ql-editor > * {
    cursor: pointer !important;
  }
}
