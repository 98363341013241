@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.asm-notes-form {
  margin-bottom: 2rem;

  textarea {
    min-height: 140px;
    max-height: 300px;
  }
  .char-count {
    text-align: right;
    color: rgba(0, 0, 0, 0.45);
  }
  button {
    margin-right: 1rem;
  }
  .ql-editor {
    max-height: 400px;
    overflow: auto;
  }
}
