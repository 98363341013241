@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.workflow-document-list {
  width: 100%;
  border: 1px solid #ececec;
  table-layout: fixed;

  &.read-only .delete {
    display: none;
  }
  thead {
    th {
      background-color: #fafafa;
      border: 1px solid #ececee;
      padding: 0.5em;
      font-size: 12px;
      height: 36px;
      color: $quest-navy;
    }
  }

  td {
    border: 1px solid #ececec;
    padding: 0.5em;
    color: $quest-navy;
    a {
      color: $quest-navy;
      text-decoration: underline;
    }

    .delete-button {
      padding: 0;
      display: inline-block;
      border: none;
      span {
        svg {
          fill: #c4c4c4;
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}
