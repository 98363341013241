@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";
@import "../../../../theme/components/extended-antd/assets/scss/variables";

.linked-accounts {
  color: $quest-navy;
  border: 1px solid $quest-dark-grey;
  padding: 0.5rem;
  height: 100%;

  .icon {
    color: $quest-red;
    vertical-align: 1px;
    font-size: 20px;
    margin-right: 0.5rem;
  }
  .header {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .linked-accounts-wrapper {
    max-height: 145px;
    overflow-x: auto;
    table {
      table-layout: fixed;
      width: 100%;

      .id-column {
        width: 80px;
      }
      .date-column {
        width: 80px;
        text-align: right;
      }
      .amount-column {
        text-align: right;
        width: 120px;
      }
      thead,
      tbody {
        td,
        th {
          &:first-child {
            padding-left: 0.25rem;
          }
          &:last-child {
            padding-right: 0.25rem;
          }
        }
      }
      thead {
        vertical-align: top;
        position: sticky;
        top: 0;
        background-color: #fff;
      }
      tbody {
        tr {
          .DECLINED {
            color: $quest-dark-grey;
          }
          &.current td {
            font-weight: bold;
            background: #ddf6ed;
          }
        }
      }
      td {
        vertical-align: top;
      }
    }
  }
}
