@import "../../../pages/loan/admin/applicant-guarantors/ApplicantGuarantors.scss";
@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.applicant-credit-score {
  @include credit-score;
  margin-bottom: 1rem;

  .ant-form-item {
    margin-bottom: 1rem;
  }
  .popover-icon {
    font-size: 24px;
    margin-bottom: 1.2rem;
  }
}
