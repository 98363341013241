@import "../../../pages/loan/admin/applicant-guarantors/ApplicantGuarantors.scss";
@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";

.guarantor-credit-score {
  @include credit-score;
  margin-bottom: 1rem;

  .ant-form-item {
    margin-bottom: 1rem;
  }
  .aml-status {
    &.fail {
      color: $quest-red;
    }
  }
  .frankieone-link {
    svg {
      vertical-align: baseline;
    }
  }
}
