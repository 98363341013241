@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/buttons";

.quest-button-square {
  @extend .quest-button;

  border-radius: 0;
  line-height: 18px !important;
  font-size: 14px;
  min-width: 100px;
  height: 32px !important;

  &.commit-transparent {
    background-color: #ffffff !important;
    border: 2px solid $quest-green !important;
    color: $quest-green !important;

    &:hover,
    &.hover {
      background-color: $quest-green !important;
      color: $quest-navy !important;
      text-decoration: none !important;
      div {
        svg {
          path {
            fill: $quest-green;
          }
        }
      }
    }
  }
}
