.ant-message {
  .anticon {
    vertical-align: 2px;
  }
  .ant-message-custom-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
  }
}

.ant-message-notice-content {
  position: relative;
}

.anticon-close-square {
  color: red !important;
}

.custom-close-icon {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}
