@import "~@quest-finance/quest-fe-shared/dist/common/assets/scss/variables";
@import "../../../common/assets/scss/button";
@import "../../assets/scss/variables";

.workflow-wrapper {
  .workflow-menu {
    display: flex;
    width: 100%;

    .filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $quest-navy;
      font-weight: bold;

      .state-btn {
        height: 32px;
        width: 32px;
        line-height: 29px;
        text-align: center;
        display: inline-block;
        border-radius: 50%;
        margin: 0 0.5rem;
        cursor: pointer;
        border: 2px solid transparent;

        &.failed {
          background-color: $state-fail;
        }
        &.on-hold {
          background-color: $state-hold;
        }
        &.refer {
          background-color: $state-refer;
        }
        &.pending {
          border-color: #c4c4c4;
        }
        &.active {
          border: 2px solid $quest-navy;
        }
      }
      .clear-filter {
        cursor: pointer;
        margin-left: 0.5rem;

        &.active {
          text-decoration: underline;
        }
      }
    }
    .rerun-wrapper {
      margin: 0 0 1rem auto;
    }
  }

  .workflow-list {
    .ant-collapse-content-box {
      padding-top: 0 !important;
      .workflow-table-wrapper {
        overflow-y: auto;
        max-height: 970px;

        .workflow-list-table {
          width: 100%;
          border-collapse: collapse;
          border-left: none;

          &.with-tasks {
            margin-bottom: 830px;
          }
          .group-header {
            font-weight: bold;
            font-size: 14px;
            background: #e5e5e5;
          }
          th,
          td {
            padding: 0.5em;
            color: $quest-navy;
            border: 1px solid #edeced;
            border-left: none;
          }
          > thead {
            vertical-align: top;
            position: sticky;
            top: -1px;
            th {
              background-color: #c4c4c4;
            }
          }
          td {
            vertical-align: top;
          }
        }
      }
    }
  }
}
